.site-footer {
  background-color: #333;
  color: rgb(217, 235, 251) !important;
  padding-left: 1em;
  padding-right: 1em;
}

.linker {
  color: rgb(0, 231, 200) !important;
  font-family: "Changa", sans-serif;
  font-size: 1.2em;
  font-weight: 200;
}

.contact-me {
  padding-left: 6px;
}

.about-me {
  color: "#d3d3d3";
  font-family: "Changa", sans-serif;
  font-size: 1.2em;
  font-weight: 200;
}

.svg-wrap {
  position: absolute;
  width: 0px;
  height: 0px;
  overflow: hidden;
}

section {
  position: relative;
  z-index: 1; /* needed for setting pseudo-element z-index */
  overflow: hidden;
  backface-visibility: hidden;
}

section span {
  position: relative;
  display: inline-flex;
  outline: none;
  vertical-align: bottom;
  text-decoration: none;
  white-space: nowrap;
}

section span::before,
section span::after {
  pointer-events: none;
  backface-visibility: hidden;
}

.link-svgmarker span svg.link-svgline {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: -1;
  overflow: hidden;
  margin: 0;
  width: 100%;
  height: 38px;
  opacity: 0.5;
  transition: stroke-dashoffset 0.3s ease-in-out;
  transform: translateY(-100%);
  fill: none;
  stroke: #41fdf4;
  stroke-width: 32;
  stroke-dasharray: 400px;
  stroke-dashoffset: 400px;
}

.link-svgmarker span:hover svg.link-svgline {
  stroke-dashoffset: 0px;
}
